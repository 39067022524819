@import "mixins";

body {
font-family: brother-1816-printed, sans-serif;
font-weight: 700;
font-style: normal;
color: $black;
}

::selection {
  background: $orange;
  color: $beige;
}

h1, h2, h3, h5 {
font-weight: 700;
line-height: 1.3;
}

h1 {
  font-size: 8.75rem;

  @include small-desktop {
    font-size: 6rem;
  }

  @include tablet {
    font-size: 4rem;
  }

  @include mobile {
    font-size: 3rem;
  }
}

h2 {
  font-size: 5rem;

  @include mobile {
    font-size: 2.5rem;
  }

  .emberfest {
    font-size: 7.5rem;
    color: $orange;
    line-height: 0;

    @include small-desktop {
      font-size: 6rem;
    }


    @include tablet {
      font-size: 4rem;
    }

    @include mobile {
      font-size: 3rem;
    }
  }
}

h3 {
  font-size: 4rem;

  @include mobile {
    font-size: 2rem;
  }
}

h4 {
  font-size: 3rem;
  font-weight: 500;
  line-height: 1.3;

  @include small-desktop {
    font-size: 2rem;
  }


  @include mobile {
    font-size: 1.5rem;
  }
}

h5 {
  font-size: 2.5rem;

  @include mobile {
    font-size: 1.5rem;
  }
}

p {
  font-weight: 300;
  font-size: 1.5rem;
  line-height: 2.4rem;

  @include mobile {
    font-size: 1.25rem;
  }
}

label, input[type="text"], input[type="email"], input[type="text"]::placeholder, input[type="email"]::placeholder  {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2.4rem;
}


