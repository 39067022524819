@import "variables";

.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.about-btns {
  display:flex;
  flex-direction: column;
  gap:3rem;

  @include desktop {
    flex-direction: row;
  }
}
