.organizers {
  display: flex;
  flex-direction: column;
  align-items: center;

  ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: start;
    gap: 3rem;
    @include tablet {
        grid-template-columns: 1fr;
    }
  }

  li {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 3rem;
  }

  .avatar {
    flex-shrink: 0;
  }
}
