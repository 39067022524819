//breakpoints
$desktop-breakpoint: 1200px;
$tablet-breakpoint: 768px;
$mobile-breakpoint: 576px;

//colors

$orange: #314199;
$beige: #FFF8EE;
$black: #000;

